html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-flow: column;
}

* {
  box-sizing: border-box;
}

main {
  transition: margin-top ease-in-out .1s;

  &.scan {
    margin-top: -130px;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  height: 70px;
  color: white;
  background: #c01717;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 32px;
}

h2 {
  height: 60px;
  color: white;
  background: #D05151;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  #printer-status-icon {
    display: none;
    margin-left: auto;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#printer-connection {
  color: forestgreen;
}

#scanner-status {
  display: block;
}

#printer-status {
  display: block;
}

#scanner-content {
  padding: 16px 32px;
}

#scanners {
  li {
    color: forestgreen;
  }

  li.error {
    color: red;
  }
}

#scanner-data-container {
  gap: 0 16px;
  align-items: center;

  > * {
    margin-top: 20px;
  }

}

#url-locale {
  margin-left: auto;
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 4px;
}

#scanner-image {
  height: 150px;
  width: 150px;
}

#scanner-data {
  font-family: "Courier New", "Courier", monospace;
  font-weight: bolder;
  overflow-wrap: anywhere;
}

#scanner-actions {
  i {
    cursor: pointer;
    font-size: 1.5rem;
  }
}

#scanner-info {
  color: darkgray;
  font-style: italic;
}

#printer-content {
  padding: 16px 32px;
  gap: 16px;
}

.printer-button {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 16px;
}

#printer-button-reconnect {
  background: #ffc107;
  color: white;
}

#printer-button-print {
  background: #007bff;
  color: white;
}

#printer-print-status {
  font-style: italic;

  &.error {
    color: red;
  }
}
